import React, { Component } from 'react';

class Skills extends Component {
  render() {
    return (
      <div className={this.props.class}>
        <h3>Languages</h3>
        <p>
          <b>Proficient:</b> C++ (w/ Qt), C#
          <br/><br/>
          <b>Familiar:</b> Javascript, CSS, HTML, QML, Java, Python, Swift, Objective C, Pascal
          <br/><br/>
        </p>
        <h3>Tools</h3>
        <p>
          Git, Gerrit, Perforce, JIRA, Confluence, Visual Studio, Xcode, Qt Creator, Kafka, ØMQ, Inno Setup
          <br/><br/>
        </p>
        <h3>OS</h3>
        <p>
          Linux, Windows, macOS
        </p>
        <br/>
      </div>
    );
  }
}

export default Skills;
