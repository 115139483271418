import React, { Component } from 'react';

class Experience extends Component {
  render() {
    return (
      <div className={this.props.class}>
        <h3>Intuit via The Cydio Group (Contractor)</h3>
        <p><i>Software Engineer 2</i>  ~  Nov. 2019 - Present</p>
        <ul>
          <li>
            Added the ability to send, receive, and display external bank acknowledgements within 
            professional tax preparation application ProSeries.
          </li>
          <li>
            Collaborated on Python scripts to execute performance testing of startup time 
            and tax calculations.
          </li>
          <li>
            Wrote unit and integration tests for various features.
          </li>
        </ul>
        <h3>Decision Sciences International Corp.</h3>
        <p><i>Software Engineer 2 (UI/UX)</i>  ~  Jul. 2017 - Nov. 2019</p>
        <ul>
          <li>
            Designed and developed UI of an application that manages the execution of a system that 
            detects materials in cargo using 3D visualization. Project written in C++ and used Qt.
          </li>
          <li>
            Redesigned and refactored UI of an application initially meant for simple demos. 
            Worked with stakeholders to determine customer needs, created wireframes, and implemented 
            features iteratively with frequent feedback to ensure requirements were fulfilled.
          </li>
          <li>
            Created mockups for discussion and feedback, documenting design process 
            and eventual implementation of user interfaces.
          </li>
          <li>
            Added data synchronization improvements to server architecture that allowed multiple 
            users to be simultaneously logged in to the same server application.
          </li> 
        </ul>
        <h3>PLEXSYS Interface Products, Inc.</h3>
        <p><i>Junior Software Engineer</i>  ~  Dec. 2014 - Mar. 2017</p>
        <ul>
          <li>
          Contributed to a small team developing a suite of radio simulation desktop applications.
          </li>
          <li>
            Led UI redesign of older application using C++, Qt, and QML.
            Arranged meetings and implemented designs using input from team, customers, and subject matter experts.
          </li> 
          <li>
            Implemented features that allowed users to control various aspects of a simulation using voice commands. 
            Studied user speech habits to optimize application’s understanding of voices.
          </li>
          <li>
            Software concepts used include client-server architecture, inter-process communication, 
            multi-threading, audio manipulation, speech recognition, and installer creation.
          </li>
        </ul>
      </div>
    );
  }
}

export default Experience;
