import React, { Component } from 'react';
import Skills from './Skills'
import Experience from './Experience'
import Education from './Education'
import ColoredLine from '../helpers/ColoredLine'
import '../style/Resume.css';

class Resume extends Component {
  render() {
    return (
      <div className="Resume">
        <div className="Resume-column">
          <Skills/>
          <ColoredLine color="#B9B896" class="Line"/>
          <Education/>
          <br/>
          <a href="https://www.dropbox.com/s/041mhqc76it07av/Mack_Hagen_Resume.pdf?dl=0" 
              target="_blank"
              rel="noopener noreferrer"
            >
            View Full Resume
          </a>
        </div>
        <ColoredLine color="#B9B896"/>
        <Experience class="Resume-column"/>
      </div>
    );
  }
}

export default Resume;
