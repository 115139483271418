import React, { Component } from 'react';
import Contact from './components/Contact'
import Resume from './components/Resume'
import ContentTab from './helpers/ContentTab'
import ColoredLine from './helpers/ColoredLine'
import './style/App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          Mack Hagen
          <ColoredLine color="#40334C"/>
          <Contact/>
        </header>
        <div className="Content">
          <ContentTab 
            component={<Resume/>} 
          />
        </div>
        <footer className="App-footer">
          &copy; {(new Date().getFullYear())} Mack Hagen ~ Made with&nbsp;
          <a href="https://reactjs.org" 
             target="_blank"
             rel="noopener noreferrer"
          >
            React
          </a>
        </footer>
      </div>
    );
  }
}

export default App;
