import React from 'react';

const ColoredLine = ({ color }) => (
  <hr
      style={{
          border: "1px solid " + color,
      }}
  />
);

export default ColoredLine;
