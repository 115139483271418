import React, { Component } from 'react';

class Education extends Component {
  render() {
    return (
      <div className={this.props.class}>
        <h3>University of Oregon</h3>
        <p>
          BS Computer & Info. Science
          <br/>
          Minors in Business, Music
          <br/>
          Graduated 2014
        </p>
      </div>
    );
  }
}

export default Education;