import React, { Component } from 'react';
import '../style/Contact.css';
import linkedin from '../resources/linkedin.png';
import github from '../resources/github.png';
import email from '../resources/email.png';
import palmtree from '../resources/palmtree.png';

class Contact extends Component {
  render() {
    return (
      <div className="Contact">
        <ul className="Contact-text">
          <li>
            <a href="mailto:mack@mackhagen.com">
              <img width="48" height="48" src={email} alt="Email link"/>
            </a>
            <a href="https://linkedin.com/in/mackhagen" 
              target="_blank"
              rel="noopener noreferrer"
            >
              <img width="48" height="48" src={linkedin} alt="LinkedIn icon"/>
            </a>
            <a href="https://github.com/mackhagen" 
              target="_blank"
              rel="noopener noreferrer"
            >
              <img width="48" height="48" src={github} alt="GitHub logo"/>
            </a>
          </li>
          <li><img width="24" height="24" src={palmtree} alt="Palm Tree"/>San Diego, CA</li>
        </ul>
      </div>
    );
  }
}

export default Contact;
